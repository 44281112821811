<template>
  <div class="app-container">
    <NavbarComp v-if="!hideLayout"/>
    <main class="main-content">
      <router-view></router-view>
    </main>
    <FooterComp v-if="!hideLayout"/>
  </div>
</template>

<script>
import NavbarComp from '@/components/NavbarComp.vue'
import FooterComp from '@/components/FooterComp.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'App',
  components: {
    NavbarComp,
    FooterComp
  },
  setup() {
    const route = useRoute()
    const hideLayout = computed(() => route.name === 'Valentine')

    return {
      hideLayout
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  background-color: #F7F7F7;
  /*display: flex;
  flex-direction: column;
  */
}
</style>
